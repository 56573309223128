import React from "react";
import { Store } from "../../Store";
import ReportMenuList from './ReportMenuList'
import Tableau from './TableauComponent/Tableau';

export default function ReportMenu(): JSX.Element {
    const { state, dispatch } = React.useContext(Store);
    const [selectedMenu, setSelectedMenu] = React.useState('Seller Geo View');
    const handleSelectMenu = (event: any, item: any) => {
        console.log({ event, item })
        setSelectedMenu(event?.target?.name);
    }
    return (
        <>
            <div className='aside reportButton'>
                <ReportMenuList selectedMenu={selectedMenu} handleSelect={handleSelectMenu} />
            </div>
            <div className='main report-main'>
                <div id='details'>
                    {selectedMenu === 'Seller Geo View' && (
                        <Tableau url='https://10az.online.tableau.com/t/dealershare/views/Seller-Geo-View/Sheet1' />
                    )}
                    {selectedMenu === 'Seller Vehicle Count' && (
                        <Tableau url='https://10az.online.tableau.com/t/dealershare/views/seller-vehicle-count/Sheet1' />
                    )}
                    {selectedMenu === 'Price Trend View' && (
                        <Tableau url='https://10az.online.tableau.com/t/dealershare/views/price-trend-view/Sheet1' />
                    )}
                </div>
            </div>

        </>
    );
}