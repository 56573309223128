import numeral from "numeral";
import React from "react";
import {
  Button,
  Carousel,
  CloseButton,
  ListGroup,
  Modal,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import CarImage from "./CarImage";
import "./SelectedCarModal.scss";
import SelectedCarData from "./SelectedCarData";
import SelectedCarDealership from "./SelectedCarDealership";
import { ICarHistory } from "../Interfaces";
import moment from "moment";

function SelectedCarModal(props: { onHideModal: any; item: any }) {
  const { onHideModal, item } = props;
  return (
    <div>
        <Modal size='lg' show={!!item} onHide={onHideModal}>
        <Modal.Header>
          <div className='header-title'>
            <Modal.Title>
              {item.year} {item.make} {item.model}
            </Modal.Title>

            {/* {dealer} | {address} */}
            <p>
              <strong>Dealer Name</strong>
              <br />
              1234 Main St. Buffalo, NY 14220
            </p>
          </div>

          <CloseButton aria-label='Hide' onClick={onHideModal} />
        </Modal.Header>
        <Modal.Body className='gallery'>
          <div className='top'>
            {/* --------- CAROUSEL -------------*/}
            <Carousel>
              {item?.scrap_image_urls?.map((url: string, index: number) => (
                <Carousel.Item key={index}>
                  <CarImage url={url} key={index} />
                </Carousel.Item>
              ))}
            </Carousel>
            {/* --------- CAROUSEL -------------*/}

            {/* --------- VEHICLE INFO -------------*/}
            <div className='vehicle-info'>
              <div className='price'>
                <div className='title'>Current Price </div>
                <h1 className='huge'>{numeral(item.price).format("$ 0,0")}</h1>
              </div>

              {item?.car_history?.length > 1 && (
                <div className='price-history'>
                  <div className='title'>Price History</div>
                  <div className='price-list'>
                    {item?.car_history
                      .filter((hist: ICarHistory, index: number) => index !== 0)
                      .map((hist: ICarHistory) => (
                        <>
                          <span>
                            <i>
                              {moment(hist.generation_process_created).format(
                                "m.d.y"
                              )}{" "}
                              &darr; {numeral(hist.price).format("$ 0,0")}
                            </i>
                          </span>
                        </>
                      ))}
                  </div>
                </div>
              )}

              <div className='numbers'>
                <div className='detail'>
                  <div className='title'>Mileage</div>
                  <div className='amount'>
                    {numeral(item.mileage).format("0,0")}
                    <span> miles</span>
                  </div>
                </div>
                <div className='detail'>
                  <div className='title'>Distance</div>
                  <div className='amount'>
                    1234<span> miles</span>
                  </div>
                </div>
                <div className='detail'>
                  <div className='title'>Lot Age</div>
                  <div className='amount'>
                    90<span>days</span>
                  </div>
                </div>
              </div>

              <div className='contact'>
                <h2>Make an offer</h2>
                <div className='actions'>
                  <Button variant='primary'>Call Now</Button>
                  <Button variant='primary'>Message</Button>
                </div>
              </div>
            </div>
            {/* --------- END VEHICLE INFO -------------*/}
          </div>

          {/* --------- TABBED FEATURES -------------*/}
          <div className='tabs'>
            <Tabs defaultActiveKey='details' id='tab-details' className='mb-3'>
              <Tab eventKey='details' title='Details'>
                <div className='vehicle-details'>
                  <div className='specs'>
                    <h5>[VIN:{item?.vin}]</h5>
                    {item.scrap_detailed_specifications?.map((specs: any) => (
                      <Table>
                        <tbody>
                          {Object.entries(specs).map((spec: any) => (
                            <>
                              <tr>
                                <div className='mt-4'>{spec[0]}</div>
                              </tr>
                              <tr className='grey'>{spec[1].join(", ")}</tr>
                            </>
                          ))}
                        </tbody>
                      </Table>
                    ))}
                  </div>
                  <div className='vehicle-all'>
                    <h4>All Features</h4>
                    <div className='features-list'>
                      <p>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                        sed diam nonumy eirmod tempor invidunt ut labore et
                        dolore magna aliquyam erat, sed diam voluptua. At vero
                        eos et accusam et justo duo dolores et ea rebum. Stet
                        clita kasd gubergren, no sea takimata sanctus est Lorem
                        ipsum dolor sit amet. Lorem ipsum dolor sit amet,
                        consetetur sadipscing elitr, sed diam nonumy eirmod
                        tempor invidunt ut labore et dolore magna aliquyam erat,
                        sed diam voluptua. At vero eos et accusam et justo duo
                        dolores et ea rebum. Stet clita kasd gubergren, no sea
                        takimata sanctus est Lorem ipsum dolor sit amet.
                      </p>
                    </div>
                  </div>
                </div>
              </Tab>
              <Tab eventKey='data' title='Data'>
                <SelectedCarData item={item}></SelectedCarData>
              </Tab>
              <Tab eventKey='dealership' title='Dealership'>
                <SelectedCarDealership item={item}></SelectedCarDealership>
              </Tab>
            </Tabs>
          </div>
          {/* --------- END TABBED FEATURES -------------*/}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
}

export default SelectedCarModal;
