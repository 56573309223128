import React from "react";
import faker from "faker";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";
import { ICar, ICarHistory } from "../Interfaces";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Car History",
    },
  },
};

function SelectedCarData(props: { item: ICar }) {
  const { item } = props;

  const labels = item?.car_history?.map(
    (item: ICarHistory) => item.generation_process_created
  );

  const data = {
    labels,
    datasets: [
      {
        label: "Car price history",
        data: item?.car_history?.map((item: ICarHistory) => item.price),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <div>
      <Line options={options} data={data} />
    </div>
  );
}

export default SelectedCarData;
