import { useState } from "react";
import { Button } from "react-bootstrap";

export default function ReportMenuList(props: { selectedMenu: any, handleSelect: any }): JSX.Element {
  let MenuList = ['Seller Geo View', 'Seller Vehicle Count', 'Price Trend View']

  return (
    <>
      <h3>Reports </h3>
      <div className="menureportlist button-menu">
        {MenuList.map(item => {
          return (
            <button type="button" name={item} className={`button-report ${props.selectedMenu === item ? 'current' : ''}`} onClick={props.handleSelect}>{item}</button>
          )
        })}
      </div>

    </>
  );
}