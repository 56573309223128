import React, { useEffect } from "react";
import { useHistory, Route, Switch, BrowserRouter as Router, Link, } from 'react-router-dom';
import { loadAuthToken, removeAuthToken, storeAuthToken } from "./Api";
import LoginHome from "./auth/LoginHome";
import { Store } from "./Store";
import DealershareHome from "./dealershare/DealershareHome";
import "./styles/index.scss";
import "./styles/styles.css";
import AlertSuccess from "./input/AlertSuccess";
import AlertDismiss from "./input/AlertError";
import Reports from '../src/components/Reports';
import { Button } from "react-bootstrap";

export default function Home() {
  const history = useHistory()
  const { state, dispatch } = React.useContext(Store);
  const handleAuthToken = (authToken: any) => {
    dispatch({ type: "FETCH_AUTH", payload: authToken });
    storeAuthToken(authToken);
  };

  const logOut = () => {
    dispatch({ type: "FETCH_AUTH", payload: "" });
    removeAuthToken();
  };

  useEffect(() => {
    const authToken = loadAuthToken();
    dispatch({ type: "FETCH_AUTH", payload: authToken });
  }, []);

  const getStoreAccessId = () => {
    return state.authToken && state.authToken.access
      ? state.authToken.access
      : "";
  };

  return (
    <>
      {!getStoreAccessId() && <LoginHome handleAuthToken={handleAuthToken} />}
      {getStoreAccessId() && state.authToken.access && (
        <>
          <div className='nav-bar'>
            <div className='logo-sm'></div>
            <Link to='/'>
              <div className='icon current'>
                <button className='icon-search'></button>
              </div>
            </Link>
            <Link to='/report'>
              <div className='icon current'>
                <button className='icon-report'></button>
              </div>
            </Link>
            {/*  <div className='icon'>
              <button className='icon-charts'></button>
            </div> 
            <div className='icon'>
              <button className='icon-account'></button>
            </div>*/}
            <div >
              <input id='logout' type='button' value='Logout' onClick={logOut} />
            </div>

          </div>
          <AlertSuccess />
          <AlertDismiss />

          <Route
            path='/report'
            exact={true}
            render={() => <Reports />}
          ></Route>


          <Route
            path='/'
            exact={true}
            render={() => <DealershareHome />}
          ></Route>
        </>)}
    </>
  );


}
