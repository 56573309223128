import React, { useEffect, useRef } from 'react';
const { tableau } = window;
const Tableau = ({ url }) => {
  const ref = useRef(null);
  function initViz() {
    new tableau.Viz(ref.current, url);
  }
  useEffect(() => {
    initViz();
  }, [])
  return (
    <div ref={ref} style={{ width: '100%', margin: 'auto', height: '100vh' }}> </div>
  );
}
export default Tableau;