import React from "react";
import { Store } from "../../Store";
import ReportMenu from './ReportMenu'

export default function Reports(): JSX.Element {
    const { state, dispatch } = React.useContext(Store);

    return (
        <>
            <div className='wrapper'>
                <ReportMenu />
            </div>
        </>
    );
}
