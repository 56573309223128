import React from "react";
import { Button } from "react-bootstrap";
import { ICar } from "../Interfaces";

function SelectedCarDealership(props: { item: ICar }) {
  const { item } = props;
  return (
    <div className="specs">
      {/* --- DEALERSHIP ---*/}
      <div className="dealership">
        <div className="dealer-title">
          <div className="logo">Logo</div>
          <div className="name">
            <h4>Dealership Name</h4>
            <p className="address">1234 Main St., Buffalo, NY 14220</p>
          </div>
        </div>
        {/* --- DEALERSHIP CONTACT---*/}
        <div className="dealer-contact">
          <Button variant="link">
            <span className="icon-call"></span> 1.716.999.000
          </Button>
          <Button variant="link">
            <span className="icon-email"></span> {item?.dealer?.url}
          </Button>
        </div>
      </div>

      <div className="staff">
        <h4>Dealer Share Managers</h4>
        <div className="person">
          <h5>Joe Smith</h5>
          <h6>Managing Director</h6>
          <Button variant="link">
            <span className="icon-call"></span> 1.716.999.000
          </Button>
          <Button variant="link">
            <span className="icon-email"></span> email@dealership.com
          </Button>
        </div>
        <div className="person">
          <h5>Joe Smith</h5>
          <h6>Managing Director</h6>
          <Button variant="link">
            <span className="icon-call"></span> 1.716.999.000
          </Button>
          <Button variant="link">
            <span className="icon-email"></span> email@dealership.com
          </Button>
        </div>
      </div>
    </div>
  );
}
export default SelectedCarDealership;
